import "../scss/style.scss";

import axios from "axios";
import "./Searchbar";
//import "./InstagramFeed";
import "./ProductGallery";

document.addEventListener("click", (e) => {
  const el = e.target.closest("button.--favorite");
  if (el) {
    const productId = parseInt(el.dataset.productId);
    const isFavorited = el.dataset.favorite === "true";

    if (!document.body.classList.contains("logged-in")) {
      window.location = `${
        window.location.origin
      }/account?favorite=${productId}&return_url=${encodeURI(
        window.location.href + window.location.search
      )}`;
    }

    if (isFavorited) {
      // Update the ui before the action is confired
      el.setAttribute("data-favorite", "false");

      const favoritesList = document.querySelector(".products.--favorites");
      if (favoritesList) {
        el.parentNode.style.opacity = 0.6;
        el.parentNode.style.pointerEvents = "none";
        el.parentNode.style.cursor = "wait";
      }

      // If there is an error return the ui the the original state
      removeFavorite(productId)
        .then(() => {
          if (favoritesList) {
            el.parentNode.remove();
            if (Array.from(favoritesList.querySelectorAll("li")).length === 0) {
              document.querySelector(".no-orders").style.display = "flex";
            }
          }
        })
        .catch(() => {
          el.setAttribute("data-favorite", "true");
          if (favoritesList) {
            el.parentNode.style.opacity = 1;
            el.parentNode.style.pointerEvents = "initial";
            el.parentNode.style.cursor = "default";
          }
        });
    } else {
      // Update the ui before the action is confired
      el.setAttribute("data-favorite", "true");

      // If there is an error return the ui the the original state

      addFavorite(productId).catch(() => {
        el.setAttribute("data-favorite", "false");
      });
    }
  }
});

const addFavorite = (productId) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append("action", "add_product_to_favorites");
    formData.append("product_id", productId);

    axios
      .post(woocommerce_params.ajax_url, formData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const removeFavorite = (productId) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append("action", "remove_product_from_favorites");
    formData.append("product_id", productId);

    axios
      .post(woocommerce_params.ajax_url, formData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

document.addEventListener("click", function (e) {
  const el = e.target.closest("button[data-tooltip]");
  if (el) {
    const tooltip = el.querySelector(".tooltip");
    if (tooltip) {
      if (tooltip.classList.contains("active")) {
        tooltip.classList.remove("active");
      } else {
        tooltip.classList.add("active");
      }
    } else {
      el.insertAdjacentHTML(
        "beforeend",
        `<span class="tooltip">${el.dataset.tooltip}</span>`
      );
      el.querySelector(".tooltip").classList.add("active");
    }
  } else {
    Array.from(document.querySelectorAll(".tooltip")).forEach((el) =>
      el.classList.remove("active")
    );
  }
});

const menuBtn = document.querySelector(".btn.--menu");
if (menuBtn) {
  menuBtn.addEventListener("click", (e) => {
    if (document.body.classList.contains("menu-active")) {
      document.body.classList.remove("menu-active");
    } else {
      document.body.classList.add("menu-active");
    }
  });
}

const filterBtn = document.querySelector(".btn.--filter");
if (filterBtn) {
  filterBtn.addEventListener("click", function (e) {
    document.body.classList.add("filter-active");
  });

  document.addEventListener("click", function (e) {
    const filter = e.target.closest(".product-filter, .btn.--filter");
    if (!filter && document.body.classList.contains("filter-active")) {
      document.body.classList.remove("filter-active");
    }
  });

  document
    .querySelector(".btn.--apply-filters")
    .addEventListener("click", function (e) {
      document.body.classList.remove("filter-active");
    });
}

var timeout;

jQuery(function ($) {
  // Lazyload lozad.js
  $("img").each(function () {
    // voegt class 'lozad' toe aan alle img's voor Lazyload behalve img's in een carousel
    $(this).not(".carousel img").not(".logo img").addClass("lozad");
    $(this)
      .not(".carousel img")
      .not(".logo img")
      .attr("data-src", $(this).attr("src"));
    $(this).not(".carousel img").not(".logo img").removeAttr("src");
  });

  // lazy loads elements with default selector as '.lozad'
  const observer = lozad();
  observer.observe();

  $('input[name="shipping_location"]').on("change", function () {
    var location = $(this).val();
    var price = $("#main_price").html().slice(0, -1);
    var get_url = $(this).closest("form").attr("action");

    // shipping costs for each set country
    var incl_shipping = "75";
    if (location === "be") {
      var incl_shipping = "150";
    }

    //$(".show_all_terms").fadeOut(100);
    $.ajax({
      type: "GET",
      url: get_url + "?sc=" + (Number(price) + Number(incl_shipping)),
      dataType: "json",
      async: false,
      success: function (data) {
        $.get(
          "/wp-content/themes/pakhuisnoord/dist/list_payment_terms.html",
          function (t) {
            $(".show_all_terms").html(
              Mustache.to_html($(t).filter("#show_terms").html(), data)
            );
            //$(".show_all_terms").fadeIn(100);
            $(".shipping_cost").html(incl_shipping);
          }
        );
      },
      error: function (data) {
        console.log(data);
      },
    });

    // $.get(get_url + "?c=" + (Number(price) + Number(incl_shipping)), function(data) {
    //     $.get('/pakhuis/wp-content/themes/pakhuisnoord/dist/list_payment_terms.html', function(t) {
    //       $(".show_all_terms").html(Mustache.to_html($(t).filter('#show_terms').html(), data));
    //       $(".show_all_terms").fadeIn(100);
    //       console.log('test');
    //     });
    //   }, "json");
  });

  $(".woocommerce").on("change", "input.qty", function () {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }

    $(".woocommerce input.qty").attr(
      "value",
      parseInt($(".woocommerce input.qty").val())
    );

    timeout = setTimeout(function () {
      $("[name='update_cart']").trigger("click");
    }, 1000); // 1 second delay, half a second (500) seems comfortable too
  });
});

const getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};

function berocket_ajax_filtering_end_handler(e) {
  let filters = getUrlParameter("filters");
  let container = jQuery(".catalog__cat-filters").first();
  let categories = jQuery(
    ".bapf_sfilter[data-taxonomy=product_cat] > .bapf_body > ul > li"
  );

  if (filters) {
    filters = filters.split("|")[0];
    let match = /product_cat\[(.*?)]/.exec(filters);
    if (Array.isArray(match) && match.length) {
      filters = match[1].split("-");
    }
  } else filters = [];

  container.empty();
  jQuery.each(categories, function (k, v) {
    let value = jQuery("input", v).first();
    let tmp = value.attr("id").split("_");

    jQuery("<div />", {
      text: value.data("name"),
      "data-name": value.data("name"),
      "data-cat_id": value.attr("id"),
      class: filters.includes(tmp[tmp.length - 1]) ? "active" : "",
    }).appendTo(container);
  });

  jQuery(".catalog__cat-filters > div").click(function () {
    jQuery("#" + jQuery(this).data("cat_id")).trigger("click");
    jQuery(this).toggleClass("active");
  });
}

/* ----- JavaScript ----- */
jQuery(document).ready(function () {
  berocket_ajax_filtering_end_handler();

  jQuery(document).on(
    "berocket_ajax_filtering_end",
    berocket_ajax_filtering_end_handler
  );
});

window.onload = function () {
  /* Cache the popup. */
  var popup = document.getElementById("header--popup");

  if (popup) {
    /* Show the popup. */
    popup.classList.remove("hidden");

    /* Fade the popup in */
    setTimeout(() => popup.classList.add("fade-in"));
    setTimeout(() => popup.classList.add("hidden"), 7000);
  }
};

//Fancybox product slider functionaliteit
window.onload = function () {
  let galleryImages = document.querySelector(".gallery__imageList");
  if (galleryImages != null) {
    for (const divImg of galleryImages.children) {
      let imgSrc = divImg.children;
      for (const divChild of imgSrc) {
        let dataSrc = divChild.getAttribute("data-src");

        divImg.setAttribute("data-fancybox", "gallery");
        divImg.setAttribute("data-src", dataSrc);
      }
    }
  }
};

document.addEventListener("DOMContentLoaded", function () {
  if (window.location.href.includes("afrekenen")) {
    const changeShippingAddress = document.getElementById(
      "ship-to-different-address-checkbox"
    );
    const shippingFields = document.querySelector(
      ".woocommerce-shipping-fields-wrapper"
    );
    const shippingFieldsInputs =
      shippingFields.querySelectorAll("input, select");
    const originalValues = {};
    shippingFieldsInputs.forEach(function (input) {
      originalValues[input.name] = input.value;
    });

    shippingFieldsInputs.forEach(function (input) {
      input.addEventListener("change", function () {
        console.log(input.value);
      });
    });

    if (changeShippingAddress) {
      changeShippingAddress.addEventListener("change", function () {
        if (this.checked) {
          shippingFields.classList.remove("visually-hidden");
        } else {
          shippingFields.classList.add("visually-hidden");
          shippingFieldsInputs.forEach(function (input) {
            input.value = originalValues[input.name];
          });
        }
      });
    }
  }
});

document.addEventListener("DOMContentLoaded", function() {
  var addressField = document.querySelector('.address-field-selector'); // Replace .address-field-selector with the actual selector for your address field
  var shippingMethods = document.querySelectorAll('.shipping-method-selector'); // Replace .shipping-method-selector with the actual selectors for your shipping methods

  addressField.addEventListener('input', function() {
      if (this.value.trim() === '') {
          shippingMethods.forEach(function(method) {
              method.disabled = true;
          });
      } else {
          shippingMethods.forEach(function(method) {
              method.disabled = false;
          });
      }
  });
});